<template>
  <ul :class="name + '-list'" class="list">
    <template v-for="(item, index) in list">
      <slot v-if="$slots.default" :key="index" :class="`${name}-list-item list-item`" :item="item" />
      <li
        v-else-if="item"
        :key="`li-${index}`"
        :class="[
          `${name}-list-item`,
          typeof item === 'object' && item.options,
          { active: active !== null && active === index, 'has-children': item.children && item.children.length }
        ]"
        class="list-item ani-main ani-intersected"
        :style="{ animationDelay: delay + (index * 0.3) + 's', minWidth: width && (width + 'em') }"
      >
        <component
          :is="serializers[item._type]"
          v-if="typeof item === 'object' && item._type && serializers"
          v-bind="{
            ...item,
            id: null,
            linkOptions: null
          }"
          class="list-item-link"
          :class="`${name}-list-link`"
        />
        <template v-else-if="action || typeof item === 'object' && (item._path || item.to || item.action)">
          <CAtomsLink
            v-bind="{
              ...item,
              id: null,
              action: typeof item.action === 'boolean' ? null : item.action,
              image: null,
              class: [`${name}-list-link`, item.linkOptions],
              to: item.to || item._path,
              text: hideText ? undefined : item.text
            }"
            class="list-item-link"
            @click="$emit('set-click', index)"
          >
            <component :is="item.iconComponent" v-if="item.iconComponent" class="icon c-link-icon list-icon" />
            <CAtomsImage
              v-if="item.image"
              class="list-image"
              :modifiers="mods"
              :class="`${name}-list-image`"
              :image="item.image"
              :alt="item.alt || item.label || item.text"
            />
          </CAtomsLink>
        </template>
        <template v-else>
          <CAtomsImage v-if="item.image" class="list-image" :modifiers="mods" :class="`${name}-list-image`" :image="item.image" :alt="item.alt || item.text" />
          <span v-if="!hideText" class="list-item-text" :class="`${name}-list-item-text`" v-text="typeof item === 'string' ? item : item.text" />
        </template>
        <!-- <div  :class="name + '-children'"> -->
        <CBlocksList v-if="item.children && item.children.length" :name="name + '-child'" :list="item.children" :delay="delay + index" />
        <!-- </div> -->
      </li>
    </template>
    <li
      v-if="marker && width"
      class="list-marking"
      :class="`${name}-list-marking`"
      :style="{ width: width * (list.length - 1) + 'em' }"
    >
      <span
        class="list-marker"
        :class="`${name}-list-marker`"
        :style="{ width: width - 1 + 'em', left: (active * width) + 0.5 + 'em' }"
      />
    </li>
  </ul>
</template>

<script lang="ts" setup>
const props = defineProps({
  list: {
    type: Array as () => Array<Record<string, any>>,
    default: () => []
  },
  name: {
    type: String,
    default: 'list'
  },
  delay: {
    type: Number,
    default: 0
  },
  hideText: {
    type: Boolean,
    default: false
  },
  active: {
    type: Number,
    default: -1
  },
  modifiers: {
    type: Object,
    default: () => ({})
  },
  width: {
    type: Number,
    default: undefined
  },
  action: Boolean,
  marker: {
    type: Boolean,
    default: false
  },
  serializers: Object
})
const mods = {
  ar: '1',
  c: 'auto',
  width: '56px',
  height: '56px',
  gravity: 'auto:subject',
  ...props.modifiers
}
defineEmits(['set-click'])
</script>

<style lang="scss">
.list {
  &-item {
    padding: czn.$sm;
    transition: czn.transition(var(--transition), (transform, opacity, max-width, max-height));
  }
}
</style>
